import {lazy} from 'react';

const FormBasic = lazy(() => import('./FormBasic'));

const Registro = lazy(() => import('./Registro'));

const FormBasic2 = lazy(() => import('./FormBasic2'));

const ActionBarForm = lazy(() => import('./ActionBarForm'));

const LayoutForm = lazy(() => import('./LayoutForm'));

const MultiColumnForms = lazy(() => import('./MultiColumnForms'));

const InputGroupForm = lazy(() => import('./InputGroupForm'));

const FormValidation = lazy(() => import('./FormValidation'));

const FormsWizard = lazy(() => import('./FormsWizard'));

const TagInput = lazy(() => import('./TagInput'));

const FormEditor = lazy(() => import('./FormEditor'));

const SubirDYP = lazy(() => import('./SubirDYP'));
const SubirDYPCorporativo = lazy(() => import('./SubirDYPCorporativo'));
const SubirNC = lazy(() => import('./SubirNC'));

const SubirIYR = lazy(() => import('./SubirIYR'));

const CargarIYR = lazy(() => import('./CargarIYR'));

const CargarNC = lazy(() => import('./CargarNC'));

const Actualizar = lazy(() => import('./Actualizar'));

const Modificar = lazy(() => import('./Modificar'));

const ModificarIYR = lazy(() => import('./ModificarIYR'));
const DescargarAnexos = lazy(() => import('./DescargarAnexos'));
const InfArch = lazy(() => import('./InfArch'));
const formsRoutes = [
  {
    path: '/forms/basic',
    component: FormBasic
  },
  {
    path: '/forms/basic2',
    component: FormBasic2
  },
  {
    path: '/forms/action-bar',
    component: ActionBarForm
  },
  {
    path: '/forms/layouts',
    component: LayoutForm
  },
  {
    path: '/forms/multi-column-forms',
    component: MultiColumnForms
  },
  {
    path: '/descargar-anexos',
    component: DescargarAnexos
  },
  {
    path: '/forms/input-group',
    component: InputGroupForm
  },
  {
    path: '/forms/form-validation',
    component: FormValidation
  },
  {
    path: '/forms/forms-wizard',
    component: FormsWizard
  },
  {
    path: '/forms/form-editor',
    component: FormEditor
  },
  {
    path: '/forms/tag-input',
    component: TagInput
  },
  {
    path: '/upload/registro',
    component: Registro
  },
  {
    path: '/subirdyp',
    component: SubirDYP
  },
  {
    path: '/subirdypcorporativo',
    component: SubirDYPCorporativo
  },
  {
    path: '/actualizar',
    component: Actualizar
  },
  {
    path: '/modificar',
    component: Modificar
  },
  {
    path: '/subiriyr',
    component: SubirIYR
  },
  {
    path: '/cargariyr',
    component: CargarIYR
  },
  {
    path: '/subirarch',
    component: InfArch
  },
  {
    path: '/editiyr',
    component: ModificarIYR
  },
  {
    path: '/subirNC',
    component: SubirNC
  },
  {
    path: '/cargarNC',
    component: CargarNC
  }
];

export default formsRoutes;
