import React, {createContext, useState, useEffect, useContext} from 'react';
import axios from 'axios';
import {host} from '../../../backend';

export const SupergestorDashboardContext = createContext();

export const SupergestorDashboardProvider = ({children}) => {
  const [allSitios, setAllSitios] = useState([]);
  const [powerBiEnabled, setPowerBiEnabled] = useState(false);
  const [cardApiEndpoints, setCardApiEndpoints] = useState([]);

  const [badgeCounts, setBadgeCounts] = useState(() => {
    // Intentar obtener los conteos de badges almacenados en localStorage
    const cachedCounts = localStorage.getItem('supergestorBadgeCounts');
    return cachedCounts ? JSON.parse(cachedCounts) : Array(cardApiEndpoints.length).fill({red: 0, yellow: 0});
  });

  const fetchBadgeCounts = async () => {
    // Inicializar un arreglo con objetos de conteo por defecto
    const initialCounts = Array(cardApiEndpoints.length).fill({
      red: 0,
      yellow: 0
    });

    const fetchedCounts = await Promise.all(
      cardApiEndpoints.map((endpoint, index) => {
        if (endpoint === null) {
          return Promise.resolve(initialCounts[index]);
        }

        const organizacion = localStorage.getItem('organizacion');

        return fetch(host + endpoint, {
          method: 'POST',
          headers: {
            Authorization: localStorage.getItem('jwt_token'),
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({organizacion_id: organizacion})
        })
          .then(res => res.json())
          .then(data => {
            return data;
          })
          .catch(err => {
            console.error(err);
            return initialCounts[index];
          });
      })
    );

    setBadgeCounts(fetchedCounts);
    localStorage.setItem('supergestorBadgeCounts', JSON.stringify(fetchedCounts));
  };

  const getAllSitios = () => {
    axios
      .post(
        host + '/listsedes',
        {
          orgid: localStorage.getItem('organizacion')
        },
        {
          headers: {
            Authorization: localStorage.getItem('jwt_token'),
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )
      .then(json => {
        setAllSitios(json.data.resp);
      })
      .catch(error => {
        console.error('Error fetching sitios:', error);
      });
  };
  const fetchPowerBiEnabled = async () => {
    try {
      const organizacionId = localStorage.getItem('organizacion');

      const response = await axios.get(`${host}/powerbi/reports`, {
        params: {sedeId: -1, organizacionId},
        headers: {
          Authorization: localStorage.getItem('jwt_token'),
          Accept: 'application/json'
        }
      });

      const data = response.data;

      setPowerBiEnabled(data && data.length > 0);
    } catch (error) {
      console.error('Error fetching PowerBI status:', error);
      setPowerBiEnabled(false);
    }
  };
  useEffect(() => {
    const initialize = async () => {
      await fetchPowerBiEnabled();

      const apiEndpoints = [
        null,
        '/docvencidoscorporativos',
        '/actividades/organizacion/instanciasatrasadasAgregadas',
        '/requisitosLegalesCorporativos/instanciasatrasadas',
        '/objetivosOrganizacion/objetivosatrasados',
        '/iniciativas/organizacion/implementacion/atrasadas',
        '/noConformidadesCorporativas/getncvencidas',
        null
      ];

      if (powerBiEnabled) {
        apiEndpoints.push(null);
      }

      setCardApiEndpoints(apiEndpoints);

      await fetchBadgeCounts();
    };

    initialize();
    getAllSitios();
  }, []);

  return <SupergestorDashboardContext.Provider value={{badgeCounts, fetchBadgeCounts, allSitios, powerBiEnabled}}>{children}</SupergestorDashboardContext.Provider>;
};

export const useSupergestorDashboardContext = () => {
  return useContext(SupergestorDashboardContext);
};
